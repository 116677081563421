/*var isCode = document.getElementsByClassName('code-block');
if (isCode.length > 0) {
  import("./components/prismjs-component" /!* webpackChunkName: "prismjs-component" *!/).then
  (instance => {
    instance.initPrismJs();
  });
}*/
import '../css/app.css';

import Prism from 'prismjs';
Prism.highlightAll();

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();

import LazyLoad from 'vanilla-lazyload';

var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});

lazyLoadInstance.update();

import Headroom from 'headroom.js';

var topBar = document.querySelector('header');

var headroomOptions = {
  offset: 105,
  tolerance: 5,
  classes: {
    initial: 'animate__animated',
    pinned: 'animate__slideInDown',
    unpinned: 'animate__slideOutUp',
  },
};
// construct an instance of Headroom, passing the element
var headroom = new Headroom(topBar, headroomOptions);
// initialise
headroom.init();

import ClipboardJS from 'clipboard';

var clipboard = new ClipboardJS('.permalink', {
  container: document.getElementById('moddal'),
});

clipboard.on('success', function (e) {
  e.trigger.innerHTML = 'Copied!';
  setTimeout(function () {
    e.trigger.innerHTML = 'Copy';
  }, 1800);
  e.clearSelection();
});

// Enable general HMR
if (import.meta.hot) {
  import.meta.hot.accept();
}
